<template>
    <div id="other_site">
        <div class="admin_main_block">
            <div class="user_temp_title">源码搭建</div>
            <div class="admin_table_main">
                <div class="page_main">
                    <div style="text-align: center;">
                        <span style="font-size: x-large; color: rgb(249, 150, 59);">源码部署,拥有属于自己的网站！</span>
                    </div>
                    <div style="text-align: center;">
                        <br>
                    </div>
                    <span style="font-size: large; color: rgb(194, 79, 74);">
                        网站完全独立控制，管理操作简单。<br>
                        全天24小时自动售单，无需人工干预。<br>
                        欢迎四海之内各位有识之士参与。<br>
                    </span><br><br>
                    <span style="font-weight: bold; font-size: large; color: rgb(70, 172, 200);">源码搭建优势</span><br>
                    <span style="color: rgb(70, 172, 200);">
                        1、平台技术支持，24小时候技术在线维护。<br>
                        2、我们提供稳定、安全、低价的快递费、礼品货源。<br>
                        3、独立域名，独立后台，自由管理。<br>
                        4、前景非常可观，客源稳定，稳定收入。<br>
                        5、搭建成本低，可以提供服务器，域名，维护，升级。<br>
                        6、源码搭建，网站API接口免费提供。
                    </span><br><br>
                    <span style="font-size: large;">
                        <br><br>
                        <span style="font-weight: bold;color:red;">一、源码搭建（一手源码）:</span>
                    </span><br>
                    <div style="color:red;"> 1.主站源码部署价格，详情咨询招商经理（搭建属于自己的礼品网）
                        <br>2.无仓库可对接发B网API，无需自己建仓
                    </div>
                    <div><br></div>
                    <span style="font-size: large;">
                        <br><br>
                        <span style="font-weight: bold;color:red;">二、分站加盟:</span>
                    </span><br>
                    <div style="color:red;"> 1.联系客服申请加盟分站
                        <br>2.开通分站需要您提供一个登陆后台的账户名，一个登陆后台的密码，客服手机号码。
                        <br>3.上线分站进行管理运营、设置快递费用、礼品价格、客服信息修改等。
                    </div>
                    <div><br></div>
                    <div>
                        <br>
                        <span style="font-size: large; font-weight: bold;">二、更多具体信息请联系招商经理</span>
                    </div><br>
                    <!-- <p style="text-align: center;">
                        <span style="font-size: x-large;">分站咨询 电话：{{$store.state.tenantInfo.phone}}</span>
                    </p> -->
                    <div v-for="item in advertList" >
                        <div v-if="item.image">
                            <img :src="item.image | fullPath" alt="" style="width: 200px;">
                        </div>
                    </div>
                    <div v-if="advertList.length == 0">
                        <img src="@/assets/img/fz.jpg" style="width: 200px;"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            advertList:[],
        }
    },
    created(){
        // this.IsPC()
        this.getAdvertList();
    },
    mounted(){},
    watch: {},
    methods: {
        IsPC(){
            var userAgentInfo = navigator.userAgent;
            var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
            var flag = true;
            for (var v = 0; v < Agents.length; v++) {
                if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }
            }
            if(!flag){
                this.$router.push('/m_othersite')
            }
        },
        getAdvertList(){
            this.$request.post({
                url:'/ReportApi/business/advert',
                params:{
                    pageNo: 1,
                    pageSize: 1,
                    type: 6,
                    tenantId: localStorage.getItem("tenantInfoid")
                },
                success: res => {
                    // if(res.length>0){
                    //     this.advert = res[0];
                    // }
                    this.advertList = res;
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
#other_site{
    .admin_main_block{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        min-height: 855px;
        border-radius: 4px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
    }
}
</style>
