var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"other_site"}},[_c('div',{staticClass:"admin_main_block"},[_c('div',{staticClass:"user_temp_title"},[_vm._v("源码搭建")]),_vm._v(" "),_c('div',{staticClass:"admin_table_main"},[_c('div',{staticClass:"page_main"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_c('br'),_c('br'),_vm._v(" "),_c('span',{staticStyle:{"font-weight":"bold","font-size":"large","color":"rgb(70, 172, 200)"}},[_vm._v("源码搭建优势")]),_c('br'),_vm._v(" "),_vm._m(3),_c('br'),_c('br'),_vm._v(" "),_vm._m(4),_c('br'),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_vm._m(7),_c('br'),_vm._v(" "),_vm._m(8),_vm._v(" "),_vm._m(9),_vm._v(" "),_vm._m(10),_c('br'),_vm._v(" "),_vm._l((_vm.advertList),function(item){return _c('div',[(item.image)?_c('div',[_c('img',{staticStyle:{"width":"200px"},attrs:{"src":_vm._f("fullPath")(item.image),"alt":""}})]):_vm._e()])}),_vm._v(" "),(_vm.advertList.length == 0)?_c('div',[_c('img',{staticStyle:{"width":"200px"},attrs:{"src":require("@/assets/img/fz.jpg")}})]):_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticStyle:{"font-size":"x-large","color":"rgb(249, 150, 59)"}},[_vm._v("源码部署,拥有属于自己的网站！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"large","color":"rgb(194, 79, 74)"}},[_vm._v("\n                    网站完全独立控制，管理操作简单。"),_c('br'),_vm._v("\n                    全天24小时自动售单，无需人工干预。"),_c('br'),_vm._v("\n                    欢迎四海之内各位有识之士参与。"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"color":"rgb(70, 172, 200)"}},[_vm._v("\n                    1、平台技术支持，24小时候技术在线维护。"),_c('br'),_vm._v("\n                    2、我们提供稳定、安全、低价的快递费、礼品货源。"),_c('br'),_vm._v("\n                    3、独立域名，独立后台，自由管理。"),_c('br'),_vm._v("\n                    4、前景非常可观，客源稳定，稳定收入。"),_c('br'),_vm._v("\n                    5、搭建成本低，可以提供服务器，域名，维护，升级。"),_c('br'),_vm._v("\n                    6、源码搭建，网站API接口免费提供。\n                ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"large"}},[_c('br'),_c('br'),_vm._v(" "),_c('span',{staticStyle:{"font-weight":"bold","color":"red"}},[_vm._v("一、源码搭建（一手源码）:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"color":"red"}},[_vm._v(" 1.主站源码部署价格，详情咨询招商经理（搭建属于自己的礼品网）\n                    "),_c('br'),_vm._v("2.无仓库可对接发B网API，无需自己建仓\n                ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"large"}},[_c('br'),_c('br'),_vm._v(" "),_c('span',{staticStyle:{"font-weight":"bold","color":"red"}},[_vm._v("二、分站加盟:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"color":"red"}},[_vm._v(" 1.联系客服申请加盟分站\n                    "),_c('br'),_vm._v("2.开通分站需要您提供一个登陆后台的账户名，一个登陆后台的密码，客服手机号码。\n                    "),_c('br'),_vm._v("3.上线分站进行管理运营、设置快递费用、礼品价格、客服信息修改等。\n                ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br'),_vm._v(" "),_c('span',{staticStyle:{"font-size":"large","font-weight":"bold"}},[_vm._v("二、更多具体信息请联系招商经理")])])
}]

export { render, staticRenderFns }